<template>
  <v-container fluid class="tb-layout-browse">
    <BreadHeader :title="model.name.plural"> </BreadHeader>

    <v-row class="tb-filter-section">
      <v-row no-gutters>
        <v-col md="4">
          <div class="tb-left-second-section">
            <div>
              <h5
                v-if="switchToAdvanceFilter == true"
                @click="switchToAdvanceFilter = false"
                style="cursor: pointer"
              >
                Switch to Normal Filter
              </h5>
              <h5
                v-if="switchToAdvanceFilter == true"
                @click="switchToAdvanceFilter = true"
                style="cursor: pointer"
              >
                Switch to Advance Filter
              </h5>
            </div>
            <div>
              <h4 v-on:click="isShow = !isShow" style="float: left">
                Filter
                <v-icon left> mdi mdi-menu-down </v-icon>
              </h4>
              <span v-if="switchToAdvanceFilter == false">
                {{ conditions.length }} Filter Applied
              </span>
            </div>
          </div>
        </v-col>
        <v-col md="8">
          <!-- include column selection compnent -->

          <BreadTabulatorSelectColumn
            ref="selectColumn"
            :model="model"
            @applyColumnSelection="applyColumnSelection"
          ></BreadTabulatorSelectColumn>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-2">
        <v-col md="12">
          <div class="tb-normal-filter" v-show="isShow">
            <div class="tb-filter-area" v-show="switchToAdvanceFilter == false">
              <BreadTabulatorFilterable
                ref="filter"
                :model="model"
                :setConditions="setConditions"
              ></BreadTabulatorFilterable>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-row>

    <!-- defult search area-->
    <div class="tb-search-area">
      <v-row>
        <v-col md="6">
          <BreadTabulatorSearchable
            ref="search"
            :model="model"
            :setConditions="setConditions"
          ></BreadTabulatorSearchable>
          <div>
            <span id="record-count" style="float: left; font-size: 12px"></span>
          </div>
        </v-col>
      </v-row>
    </div>

    <BreadTabulator
      ref="tabulatorDataTable"
      layout="fitColumns"
      :autoColumns="false"
      :paginationSize="paginationSize"
      :paginationSizeSelector="paginationSizeSelector"
      :apiUrl="apiGetBrowseUrl()"
      :columns="columns"
      :modelKey="modelKey"
      :columnSelectionDisabledCount="columnSelectionDisabledCount"
      @updateEditedCellData="updateEditedCellData"
      @getCurrentPageSize="getCurrentPageSize"
      @getCurrentSort="getCurrentSort"
      @getCurrentColumnWidth="getCurrentColumnWidth"
      @getCurrentPageNumber="getCurrentPageNumber"
    ></BreadTabulator>
    <!-- EOC -->
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_einvoicing/objects/globalData";

export default {
  mixins: [hrdcFunction],
  components: {},
  computed: mapState({
    auth: (state) => state.auth.data,
    hrdc_einvoicing: (state) => state.hrdc_einvoicing.data,
  }),
  props: ["params"],
  data: () => ({
    api: new Api(),
    conditions: [],
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    listAllViews: [],
    columns: [],
    paginationSize: 50,
    paginationSizeSelector: [50, 100, 150, 200],
    modelKey: "",
    parentId: "",
    cColumnSort: [],
    displayRowCount: 10,
    selectedCustomColums: [],
    selectedFilterConditions: [],
    switchToAdvanceFilter: false,
    isShow: false,
    allResizeColumns: [],
    columnSelectionDisabledCount: 2,
    currentPageNo: 1,
  }),
  async created() {
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    this.modelKey = this.$route.params.modelKey;
    this.parentId = this.$route.params.parentId;

    // Perform first check on user role, before enter (to prevent salesperson enter to unwanted view).
    this.roleMiddleware(this.auth.roles, this.service.key);

    this.columns = this.$_.filter(
      this.model.browse.table.headers,
      (headers) => {
        return headers;
      }
    );

    var columnIndex = 0;
    for (var column of this.columns) {
      if (column.field == "company") {
        this.columns[columnIndex].cellClick = (e, cell) => {
          const { applicationUuid, stageId } = cell.getData();

          this.$store.commit("assignHrdcEinvoicingData", {
            applicationUuid,
            stageId,
            viewId: this.$route.query.viewId,
          });

          const routeData = this.$router.resolve({
            name: "ViewHrdcEinvoicingApplication",
            query: { currentPageNo: this.currentPageNo },
          });
          window.open(routeData.href, "_blank");
        };
      }

      if (column.dataType == "date") {
        this.columns[columnIndex].formatter = (cell) => {
          var date = cell.getValue();
          if (date) {
            return this.$moment(new Date(date)).format("YYYY-MM-DD");
          }
        };
      }

      columnIndex++;
    }

    this.stages = await this.hrdcData.allStages;
    this.hrdcData.rolesAssignation(this.auth.roles);
  },
  mounted() {
    if (
      this.$route.query.viewId != "all" &&
      this.$route.query.viewId != "trashed"
    ) {
      const viewId = parseInt(this.$route.query.viewId, 10);
      this.getAllViews(viewId);
    }
    if (this.$route.query.viewId == "all") {
      this.getAllViews("all");
    }

    if (this.$route.query.viewId == "trashed") {
      this.getAllViews("trashed");
    }
  },
  methods: {
    getConditions() {
      return this.conditions;
    },
    setConditions(conditions) {
      this.selectedFilterConditions = conditions;
      this.conditions = conditions;
      this.$refs.tabulatorDataTable.getFilterCondition(conditions);
    },
    apiGetBrowseUrl() {
      return `${this.$service[this.service.key]}/v1/en/console/${
        this.model.key
      }`;
    },
    setFiltersViaConditions(conditions) {
      this.$refs.filter.setFiltersViaConditions(conditions);
    },
    getCurrentPageSize(pagesize) {
      this.displayRowCount = pagesize;
    },
    getCurrentSort(sorters) {
      this.cColumnSort = sorters;
      this.cColumnSort = sorters.map((sorter) => {
        return {
          column: sorter.field,
          dir: sorter.dir,
        };
      });
    },
    getCurrentColumnWidth(resizedColumns) {
      const resizedColumnField = resizedColumns.getField();
      const resizedColumnWidth = resizedColumns.getWidth();

      const resizedColumnWidthAndField = {
        field: resizedColumnField,
        width: resizedColumnWidth,
      };

      const index = this.allResizeColumns.findIndex((item) => {
        return item.field === resizedColumnField;
      });

      if (index !== -1) {
        this.allResizeColumns[index].width = resizedColumnWidth;
      } else {
        this.allResizeColumns.push(resizedColumnWidthAndField);
      }
    },
    changeTableDetails(listAllViews, viewId) {
      if (viewId !== "all") {
        const tabIndex =
          listAllViews.indexOf(listAllViews.find((x) => x.id === viewId)) + 1;

        this.$refs.customView.changeSelectedTabStatus(viewId, tabIndex);
        const singleViewDetail = listAllViews.filter((item) => {
          return item.id === viewId;
        });

        if (singleViewDetail.length > 0) {
          if (singleViewDetail[0].manageColumnCustomWidth) {
            this.columns.forEach((column) => {
              column.width = "";
            });

            this.$refs.tabulatorDataTable.callSavedHeaderWidth(
              JSON.parse(singleViewDetail[0].manageColumnCustomWidth)
            );
          } else {
            this.$refs.tabulatorDataTable.callSavedHeaderWidth([]);
          }

          this.$refs.selectColumn.showViewSelectedColumns(
            JSON.parse(singleViewDetail[0].cColumnCondition)
          );

          this.$refs.filter.setFiltersViaConditions(
            JSON.parse(singleViewDetail[0].filterCondition)
          );

          if (singleViewDetail[0].cColumnSort) {
            this.$refs.tabulatorDataTable.callSavedColumnSort(
              JSON.parse(singleViewDetail[0].cColumnSort)
            );
          } else {
            this.$refs.tabulatorDataTable.callSavedColumnSort([]);
          }

          // set table row size
          this.$refs.tabulatorDataTable.callSavedPageSize(
            singleViewDetail[0].displayRowCount
          );
        } else {
          this.$refs.selectColumn.showViewSelectedColumns([]);
          this.$refs.filter.setFiltersViaConditions([]);
        }

        if (viewId == "trashed") {
          this.$refs.tabulatorDataTable.callApiUrl(this.apiGetBrowseTrashUrl());
          this.$refs.selectColumn.showAllColumns([]);
          this.$refs.filter.setFiltersViaConditions([]);
          this.$refs.tabulatorDataTable.callSavedPageSize(this.paginationSize);
          this.$refs.tabulatorDataTable.callSavedColumnSort([]);
          this.$refs.tabulatorDataTable.callSavedHeaderWidth([]);
          this.$refs.customView.changeSelectedTabStatus("trashed", 0);
        }

        this.setCurrentPageTo();
      } else {
        this.$refs.selectColumn.showAllColumns([]);
        this.$refs.filter.setFiltersViaConditions([]);
        this.$refs.tabulatorDataTable.callSavedPageSize(this.paginationSize);
        this.$refs.tabulatorDataTable.callSavedColumnSort([]);
        this.$refs.tabulatorDataTable.callSavedHeaderWidth([]);
        this.$refs.customView.changeSelectedTabStatus("all", 1);
        this.setCurrentPageTo();
      }
    },
    getAllViews(viewId, tabIndex) {
      this.api.setMethod(`GET`);
      this.api.setUrl(
        `${this.$service.crm}/v1/en/console/crmView/list?modelKey=${this.model.key}`
      );
      this.api.setCallbackCompleted((response) => {
        const privateViews = response.filter((view) => {
          return view.isPrivate === true && view.ownerUuid === this.auth.uuid;
        });

        // get all public views
        const publicViews = response.filter((view) => {
          return view.isPrivate === false;
        });

        // push all private and public views to listAllViews
        this.listAllViews = [...publicViews, ...privateViews];
        this.changeTableDetails(this.listAllViews, viewId, tabIndex);
      });
      this.api.fetch();
    },
    applyColumnSelection(selectedColumns) {
      this.selectedCustomColums = selectedColumns;
      this.$refs.tabulatorDataTable.applyColumnSelection(selectedColumns);
    },
    getCurrentPageNumber(pageno) {
      this.currentPageNo = pageno;
    },
    setCurrentPageTo() {
      var pageNo =
        this.$store.state.developer.data.paginationPageNo != null
          ? this.$store.state.developer.data.paginationPageNo
          : 1;
      this.$refs.tabulatorDataTable.tabulator.setPage(pageNo);
      this.$store.commit("assignDeveloperData", {
        paginationPageNo: null,
      });
    },
    updateEditedCellData(cell) {
      let editedRecordField = cell.getField();
      let editedRecordValue = cell.getValue();
      let rowData = cell.getData();
      const { applicationUuid, stageId } = rowData;
      const stagePriorNeedToBeExceed = this.hrdcData.stageIndex[stageId];

      let initialValue = null;
      let editMode = false;
      let originalInitialValue = cell.getInitialValue();
      if (originalInitialValue) {
        initialValue = this.$moment(new Date(originalInitialValue)).format(
          "YYYY-MM-DD"
        );
        editMode = true;
      }

      let columnDataType = cell.getColumn().getDefinition().dataType;

      if (columnDataType == "date") {
        if (editedRecordValue) {
          editedRecordValue = new Date(editedRecordValue);
        }
      }

      if (columnDataType == "dateWithBgColor") {
        if (editedRecordValue) {
          editedRecordValue = new Date(editedRecordValue);
        }
      }

      // Set api url based on action.
      const data = this.getUpdateStageData(editedRecordField);

      // Check is this taxpod admin (only taxpod admin allow to edit dates).
      if (!this.hrdcData.isTaxpodAdminRoles) {
        this.$store.dispatch("showMessage", {
          message: "Only taxPOD Admin allow to edit.",
          messageType: "error",
          timeout: 2000,
        });
        cell.setValue(null, true);
        return;
      }

      // Check stage priority.
      if (
        data.stageToExceed > stagePriorNeedToBeExceed ||
        stagePriorNeedToBeExceed == undefined
      ) {
        this.$store.dispatch("showMessage", {
          message: "Please complete previous step to update this date",
          messageType: "error",
          timeout: 2000,
        });
        cell.setValue(null, true);
        return;
      }

      // Only update if value is not empty.
      if (editedRecordValue) {
        this.api.setMethod("POST");
        let params = {
          applicationUuid,
          referById: this.auth.uuid,
          editMode,
        };

        params[data.dateTerm] = editedRecordValue;

        this.api.setParams(params);
        this.api.setUrl(
          `${this.$service[this.service.key]}/v1/en/console/${data.url}`
        );

        this.api.setCallbackCompleted(() => {
          this.$store.dispatch("showMessage", {
            message: "Updated successfully.",
            messageType: "success",
            timeout: 2000,
          });

          // Set new stageId.
          cell.getRow().getData().stageId = data.nextStageId;

          // Set refund email updated date +14 days.
          if (editedRecordField == "refundEmailArToAp") {
            cell
              .getRow()
              .getCell("refundEmailUpdated")
              .setValue(
                editedRecordValue.setDate(editedRecordValue.getDate() + 14)
              );
          }
        });

        this.api.fetch();
      } else {
        cell.setValue(initialValue, true);
      }
    },
    getUpdateStageData(stage) {
      let data = {};
      switch (stage) {
        case "claimApprovedFromHrdc":
          data.url = "claim_approved/update-claim-approved";
          data.dateTerm = "claimApprovedDate";
          data.stageToExceed = this.hrdcData.stageSlug.submittedClaimToHrdc; // 1500
          data.nextStageId = this.hrdcData.stagePriority.claimApprovedFromHrdc;
          break;

        case "paymentReceivedFromHrdc":
          data.url = "payment_received/create-payment-received";
          data.dateTerm = "paymentReceivedDate";
          data.stageToExceed = this.hrdcData.stageSlug.claimApprovedFromHrdc;
          data.nextStageId =
            this.hrdcData.stagePriority.paymentReceivedFromHrdc;
          break;

        case "creditNoteRequested":
          data.url = "credit_note_requested/create-cn-requested";
          data.dateTerm = "cnRequestedDate";
          data.stageToExceed = this.hrdcData.stageSlug.paymentReceivedFromHrdc;
          data.nextStageId = this.hrdcData.stagePriority.creditNoteRequested;
          break;

        case "creditNoteDocumented":
          data.url = "credit_note_documented/create-cn-documented";
          data.dateTerm = "cnDocumentedDate";
          data.stageToExceed = this.hrdcData.stageSlug.creditNoteRequested;
          data.nextStageId = this.hrdcData.stagePriority.creditNoteDocumented;
          break;

        case "refundEmailArToAp":
          data.url = "refund_email_ar_to_ap/create-refund-email-ar-to-ap";
          data.dateTerm = "refundEmailArToApDate";
          data.stageToExceed = this.hrdcData.stageSlug.refundEmailApproval2;
          data.nextStageId = this.hrdcData.stagePriority.refundEmailArToAp;
          break;

        case "financeRefundToClient":
          data.url = "finance_refund/create-finance-refund";
          data.dateTerm = "financeRefundToClientDate";
          data.stageToExceed = this.hrdcData.stageSlug.refundEmailArToAp;
          data.nextStageId = this.hrdcData.stagePriority.financeRefundToClient;
          break;

        case "emailPaymentAdviceToClient":
          data.url = "email_payment_advice/create-email-payment-advice";
          data.dateTerm = "emailPaymentAdviceToClientDate";
          break;
      }

      return data;
    },
  },
};
</script>
